'use strict';

import * as utils from './utils.js';
import * as oaUtils from './open-access-utils.js';
import axios from "axios";

// Endpoints for both the elp-helper plugin, and WooCommerce / WordPress
const apiEndpoints = {
  customerExists: '/wp-json/elp-helper/v1/customer-exists/',
  loginOrCreate: '/wp-json/elp-helper/v1/customer/login-or-create',
  customer: '/wp-json/elp-helper/v1/customer'
};

// Constants for meaningful code
export const FORCE_NEW_CO_CART = true;
export const WITHOUT_CART_FETCH = true;

let CoCart;

export function fetchCoCart(forceNewCoCart = false, version = 2) {
  if (CoCart && !forceNewCoCart) return CoCart;

  const baseURL = `https://${location.hostname}/wp-json/cocart/v${version}`;
  CoCart = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  console.warn("Guest checkout mode: No consumerKey or consumerSecret provided. Operating without authentication.");
  return CoCart;
}

export function fetchCartKey() {
  let cartKey = localStorage.getItem('cartKey');
  if (!cartKey) {
    cartKey = `${Math.random().toString(36).substring(2, 15)}${Math.random().toString(36).substring(2, 15)}`;
    localStorage.setItem('cartKey', cartKey);
  }
  return cartKey;
}

export function generateCartUrl(url) {
  const cartKey = fetchCartKey();
  return `${url}?cart_key=${cartKey}&nocache=${utils.uuid()}`;
}

export async function fetchCart() {
  try {
    const response = await fetchCoCart().get(generateCartUrl('cart'));
    if (response.data) {
      utils.dispatchGlobal('elp:cart:fetched', { data: response.data });
      localStorage.setItem('cartData', JSON.stringify(response.data)); // Store cart data for later use
    }
  } catch (error) {
    console.error("Error fetching cart:", error.response?.data || error.message);
  }
}

export async function addItemsToCart(itemsToAdd, callback) {
  utils.dispatchGlobal('elp:cart:changed');

  const items = Array.isArray(itemsToAdd) ? [...itemsToAdd] : [itemsToAdd];

  while (items.length > 0) {
    const itemToAdd = items.pop();
    try {
      await fetchCoCart().post(generateCartUrl('cart/add-item'), itemToAdd);
    } catch (error) {
      console.error("Error adding item to cart:", error.response?.data || error.message);
    }
  }

  utils.dispatchGlobal('elp:cart:fetch');
  if (callback) callback();
}

export async function removeCartItems(itemsToRemove, callback, isPassive = false) {
  const items = Array.isArray(itemsToRemove) ? [...itemsToRemove] : [itemsToRemove];

  for (const itemKey of items) {
    await removeCartItem(itemKey, null, true);
  }

  if (!isPassive) {
    utils.dispatchGlobal('elp:cart:fetch');
  }

  if (callback) callback();
}

export async function removeCartItem(itemKey, callback, isPassive = false) {
  utils.dispatchGlobal('elp:cart:changed');

  try {
    await fetchCoCart().delete(generateCartUrl(`cart/item/${itemKey}`));
    if (callback) callback();
  } catch (error) {
    console.error("Error removing item from cart:", error.response?.data || error.message);
  }

  if (!isPassive) {
    utils.dispatchGlobal('elp:cart:fetch');
  }
}

export async function replaceCartItem(oldItemKey, itemToAdd) {
  utils.dispatchGlobal('elp:cart:changed');
  try {
    await removeCartItem(oldItemKey);
    await addItemsToCart(itemToAdd);
  } catch (error) {
    console.error("Error replacing cart item:", error.response?.data || error.message);
  }
}

export async function updateCartItemQuantity(itemKey, qty) {
  utils.dispatchGlobal('elp:cart:changed');
  try {
    await updateCartItem(itemKey, { quantity: String(qty) });
  } catch (error) {
    console.error("Error updating cart item quantity:", error.response?.data || error.message);
  }
}

export async function updateCartItem(itemKey, data) {
  utils.dispatchGlobal('elp:cart:changed');
  try {
    await fetchCoCart().post(generateCartUrl(`cart/item/${itemKey}`), data);
  } catch (error) {
    console.error("Error updating cart item:", error.response?.data || error.message);
  } finally {
    utils.dispatchGlobal('elp:cart:fetch');
  }
}

export async function checkUserExists(emailAddress, callback) {
  try {
    const response = await axios.get(apiEndpoints.customerExists + emailAddress);
    callback(response.data);
  } catch (error) {
    console.error("Error checking if user exists:", error.response?.data || error.message);
    callback(null);
  }
}

export async function loginOrCreateCustomer(email, password, callback) {
  const customerData = { email, password };

  try {
    const response = await axios.patch(apiEndpoints.loginOrCreate, customerData);
    callback(response.data);
  } catch (error) {
    console.error("Error logging in or creating customer:", error.response?.data || error.message);
    callback(null);
  }
}

export async function logout() {
  try {
    await fetchCoCart().post(generateCartUrl('logout'));
    utils.dispatchGlobal('elp:account:logged-out');
  } catch (error) {
    console.error("Error logging out:", error.response?.data || error.message);
  }
}

export async function applyCoupon(couponData) {
  try {
    await fetchCoCart(true, 1).post(generateCartUrl('coupon'), couponData);
    utils.dispatchGlobal('elp:cart:coupon-added');
  } catch (error) {
    console.error("Error applying coupon:", error.response?.data || error.message);
  } finally {
    await fetchCart();
  }
}

export async function removeCoupon(couponData) {
  try {
    await fetchCoCart(true, 1).delete(generateCartUrl('coupon'), { data: couponData });
  } catch (error) {
    console.error("Error removing coupon:", error.response?.data || error.message);
  } finally {
    await fetchCart();
  }
}

export async function updateCustomer(customerData, callback) {
  try {
    const response = await axios.put(apiEndpoints.customer, customerData, {
      headers: headersWithCredentials(),
    });
    callback(response.data);
  } catch (error) {
    console.error("Error updating customer:", error.response?.data || error.message);
    callback(null);
  }
}

function headersWithCredentials() {
  return {
    'x-elp-authorization': utils.get('x-elp-authorization'),
    'Content-Type': 'application/json'
  };
}
