'use strict';

import * as cartApi from "./checkout-cart-api.js";
import * as utils from "./utils.js";

const resourceNameToIdMapping = {
  'Screen': 401,
  'Lino': 400,
  'Fabric': 381,
  'Etching': 378
};

const resourceIdToNameMapping = {
  401: 'Screen',
  400: 'Lino',
  381: 'Fabric',
  378: 'Etching'
};

export function fetchSingleTimeslotAndResourceAvailability(
  productId,
  resourceId,
  targetDate,
  callback
) {
  let tomorrow = new Date(targetDate.getTime());
  tomorrow.setDate(tomorrow.getDate() + 1);

  const params = new URLSearchParams({
    min_date: targetDate.toISOString().slice(0, 10),
    max_date: tomorrow.toISOString().slice(0, 10),
    product_ids: productId,
    resource_ids: resourceId,
  });

  // Load availability for the month
  fetch('/wp-json/wc-bookings/v1/products/slots?' + params.toString())
    .then(response => response.json())
    .then(
      (data) => {
        if (
          data.hasOwnProperty('records')
          && Array.isArray(data.records)
          && data.records.length
        ) {
          data.records.forEach(
            (singleSessionRecord) => {
              let sessionDateTime = new Date(singleSessionRecord.date);
              if (sessionDateTime.getUTCHours() == targetDate.getUTCHours()) {
                callback(singleSessionRecord);
              }
            }
          )
        } else {
          callback(false);
        }
      }
    )
  ;
}

export function updateOpenAccessSessionQuantity(
  cartItemData,
  newQty
) {
  let bookingInfo = cartItemData.cart_item_data;
  let updatedCartItemData = {
    id: String(cartItemData.id),
    item_data: {
      post_passthrough: true,
      wc_bookings_field_persons: newQty,
      wc_bookings_field_resource: bookingInfo.wc_bookings_field_resource,
      wc_bookings_field_start_date_day: bookingInfo.wc_bookings_field_start_date_day,
      wc_bookings_field_start_date_month: bookingInfo.wc_bookings_field_start_date_month,
      wc_bookings_field_start_date_time: bookingInfo.wc_bookings_field_start_date_time
    }
  }

  cartApi.replaceCartItem(cartItemData.item_key, updatedCartItemData);
}

export function fetchOpenAccessCartItemsByResourceIdAndDatetime(
  resourceId,
  date
) {
  if (
    !('elpCartState' in window)
    || !('data' in window.elpCartState)
    || !('items' in window.elpCartState.data)
    || !(Array.isArray(window.elpCartState.data.items))
  ) {
    return 0;
  }

  let totalAlreadyInCart = 0;
  window.elpCartState.data.items.forEach(
    (cartItem) => {
      if (
        !cartItemIsOpenAccess(cartItem)
        || !('cart_item_data' in cartItem)
        || cartItem.cart_item_data.wc_bookings_field_resource !== resourceId
        || cartItem.cart_item_data.wc_bookings_field_start_date_time.substr(0,16) !== date
      ) {
        return;
      }

      totalAlreadyInCart = cartItem.cart_item_data.wc_bookings_field_persons;
    }
  );

  return totalAlreadyInCart;
}

export function getResourceIdByName(name) {
  if (name in resourceNameToIdMapping) {
    return resourceNameToIdMapping[name];
  } else {
    return null;
  }
}

export function getResourceNameById(id) {
  if (id in resourceIdToNameMapping) {
    return resourceIdToNameMapping[id];
  } else {
    return null;
  }
}

/**
 * Returns an array of all OA types in the cart; "etching", "fabric", etc
 */
export function fetchAllOpenAccessTypesInCart(cartItems)
{

  if (typeof cartItems === 'undefined') {
    cartItems = window.elpCartState.data.items;
  }

  let openAccessTypes = [];
  cartItems
    .forEach(
      (cartItem) => {
        if (cartItemIsOpenAccess(cartItem)) {
          let resourceName = getResourceNameById(cartItem.cart_item_data.wc_bookings_field_resource);
          openAccessTypes[resourceName] = true;
        }
      }
  );

    let returnArray = [];
    for(let resourceName in openAccessTypes) {
      returnArray.push(resourceName);
    }

    return returnArray;
}

export function fetchOpenAccessCartItemsByResourceId(cartItems, resourceId)
{
  let cartItemsOfType = [];
  cartItems
    .forEach(
      (cartItem) => {
        if (
          cartItemIsOpenAccess(cartItem)
          && cartItem.cart_item_data.wc_bookings_field_resource === resourceId
        ) {
          cartItemsOfType.push(cartItem);
        }
      }
    )
  ;

  return cartItemsOfType;
}

export function cartItemIsOpenAccess(cartItem)
{
  return cartItem.name === 'Open Access';
}

export function fetchOpenAccessCartItemByResourceIdAndDatetime(
  resourceId,
  dateTime
) {
  if (
    !('elpCartState' in window)
    || !('data' in window.elpCartState)
    || !('items' in window.elpCartState.data)
    || !Array.isArray(window.elpCartState.data.items)
    || !window.elpCartState.data.items.length
  ) {
    return null;
  }

  for (let i in window.elpCartState.data.items) {
    let cartItem = window.elpCartState.data.items[i];
    if (
      cartItemIsOpenAccess(cartItem)
      && cartItem.cart_item_data.wc_bookings_field_resource === resourceId
      && cartItem.cart_item_data.wc_bookings_field_start_date_time === dateTime

    ) {
      return cartItem;
    }
  }

  return null;
}

export function getAttendeeData(itemKey, attendeeIndex)
{
  let attendeeData;
  const attendeeUuid = utils.get(`attendee_${itemKey}_${attendeeIndex}`);
  const allAttendees = getAllKnownAttendees();

  if (
    attendeeUuid
    && attendeeUuid in allAttendees
  ) {
    attendeeData = allAttendees[attendeeUuid];
  } else {
    attendeeData = {
      uuid: 'biller',
      is_biller: true
    };

    utils.set(`attendee_${itemKey}_${attendeeIndex}`, attendeeData.uuid);
  }

  // Biller info is always re-loaded from the cart
  if (attendeeData.is_biller) {
    attendeeData = {
      uuid: 'biller',
      first_name: window.elpCartState.data.customer.billing_address.billing_first_name,
      last_name: window.elpCartState.data.customer.billing_address.billing_last_name,
      phone: window.elpCartState.data.customer.billing_address.billing_phone,
      email: window.elpCartState.data.customer.billing_address.billing_email,
      is_biller: true
    };
  }

  return attendeeData;
}

export function setAttendeeData(itemKey, attendeeIndex, attendeeData)
{
  if (!attendeeData.hasOwnProperty('uuid')) {
    attendeeData.uuid = utils.uuid();
  }

  attendeeData.current_attendee = attendeeData.uuid;

  // Add the attendee to the dictionary of knownm attendees
  const allAttendees = getAllKnownAttendees();
  allAttendees[attendeeData.uuid] = attendeeData;
  utils.set('attendees', JSON.stringify(allAttendees));

  // And associate the attendee with this OA session and attendee index (for when there is more than one attendee per session)
  utils.set(`attendee_${itemKey}_${attendeeIndex}`, attendeeData.uuid);

  return attendeeData;
}

export function getAllKnownAttendees()
{
  let allAttendees = utils.get('attendees');
  return allAttendees ? JSON.parse(allAttendees) : {};
}
