'use strict';

const localStoragePrefix = 'elp__';

export function fetchTemplate(selector, container) {
  let templateNode = (container || window.document).querySelector(selector);

  return templateNode ? templateNode.content.childNodes[1] : false;
}

export function cloneTemplate(selector, container) {
  let template = fetchTemplate(selector, container);
  return template ? template.cloneNode(true) : false;
}

// Returns "Thursday 7th July"
export function dateToHuman(date) {
  let months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
  let days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
  let suffixes = ['','st','nd','rd','th','th','th','th','th','th','th','th','th','th','th','th','th','th','th','th','th','st','nd','rd','th','th','th','th','th','th','th','st'];

  let day = days[date.getDay()];
  let dateNumeral = date.getDate();
  let suffix = suffixes[date.getDate()];
  let month = months[date.getUTCMonth()];
  
  return `${day} ${dateNumeral}${suffix} ${month}`;
}

// Return a yyyy-mm-dd
export function dateToNonShitDate(date) {
  let yearString = date.getFullYear();
  let monthString = ('0'+(date.getMonth()+1)).substr(-2);
  let dateString = ('0' + date.getDate()).substr(-2);

  return yearString+'-'+monthString+'-'+dateString;
}

export function isMorningOrAfternoon(dateString) {
  switch (dateString.substr(11,2)) {
    case '10':
      return 'morning';
      break;

    case '15':
      return 'afternoon';
      break;

    default:
      return null;
  }
}

export function dateToOpenAccessDate(dateObject, morningOrAfternoon) {
  let dateString = dateToNonShitDate(dateObject);
  switch (morningOrAfternoon) {
    case 'morning':
      return dateString+'T10:00:00+0100';
      break;
    case 'afternoon':
      return dateString+'T15:00:00+0100';
      break;
  }

  return '';
}

export function dateToMonthSafeDate(date) {
  // create a date that is the 2rd of the current month
  // - we don't use the 1st in case timezone weirdness moves it to the month before
  // - we don't use the current date in case we're on the 31st and adding a month moves us 1 more than expected
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    2
  );
}

export function paddedNumber(num) {
  return ('0' + num).substr(-2)
}

export function emptyDomElement(domElement) {
  while (domElement.hasChildNodes()) {
    domElement.removeChild(domElement.lastChild);
  }
}

export function isEmptyArray(thing) {
  return (Array.isArray(thing) && thing.length === 0);
}

export function toTitleCase(str) {
  return str.replace(
    /\w*/g,
    (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export function addQuantitySelector(
  container,
  currentValue,
  minValue,
  maxValue,
  dataObject
) {
  // Build the template
  let template = document
    .querySelector('[elp-template="quantity-selector"]')
    .content
    .childNodes[1]
    .cloneNode(true)
  ;

  // WP uses -1 for infinite
  if (maxValue < 0) {
    maxValue = 99;
  }

  // Add the up / down nav event listeners
  template
    .querySelectorAll('[qty-nav]')
    .forEach(
      (navElement) => {
        navElement.addEventListener(
          'click',
          (event) => {
            let buttonElement = event.target.closest('[qty-nav]');
            let newValue = currentValue + (buttonElement.getAttribute('qty-nav') == 'up' ? 1 : -1);

            // quick sanity check
            if (newValue > maxValue || newValue < minValue) {
              return;
            }

            setValue(newValue);
          },
          { passive: true }
        );
      }
    )
  ;

  function setValue(newValue, suppressEvent) {
    dataObject.quantities = {
      previous_value: currentValue,
      new_value: newValue
    };

    let previousValue = currentValue;
    currentValue = newValue;
    template.querySelector('[qty-value]').innerText = currentValue;

    // Enable / disable up & down nav
    template.querySelector('[qty-nav="up"]').disabled = (currentValue >= maxValue);
    template.querySelector('[qty-nav="down"]').disabled = (currentValue <= 0);

    if (!suppressEvent) {
      container.dispatchEvent(new CustomEvent(
        'elp:cart:qty-changed',
        {
          bubbles: true,
          detail: {
            newValue: currentValue,
            previousValue: previousValue,
            data: dataObject
          }
        }
      ));
    }
  }

  // Add the selector to the DOM
  container.append(template);

  // Set the initial value
  setValue(currentValue, true);
}

export function dispatchGlobal(eventName, data)
{
  document
    .querySelector('body')
    .dispatchEvent(
      new CustomEvent(
        eventName,
        data ? { detail: data } : null
      )
    )
  ;
}

export function set(label, value)
{
  if (
    'localStorage' in window
    && window.localStorage instanceof Storage
  ) {
    if (value === null) {
      window.localStorage.removeItem(localStoragePrefix + label);
    } else {
      window.localStorage.setItem(localStoragePrefix + label, value);
    }
  } else {
    // TODO; fallback to cookies?
  }
}

export function get(label) {
  if (
    'localStorage' in window
    && window.localStorage instanceof Storage
  ) {
    const value = window.localStorage.getItem(localStoragePrefix + label);
    switch (value) {
      case 'true':
        return true;

      case 'false':
        return false;

      default:
        return value;
    }
  } else {
    // TODO; fallback to cookies?
  }
}

export function setSession(label, value)
{
  if (
    'sessionStorage' in window
    && window.sessionStorage instanceof Storage
  ) {
    if (value === null) {
      window.sessionStorage.removeItem(localStoragePrefix + label);
    } else {
      window.sessionStorage.setItem(localStoragePrefix + label, value);
    }
  } else {
    // TODO; fallback to cookies?
  }
}

export function getSession(label) {
  if (
    'sessionStorage' in window
    && window.sessionStorage instanceof Storage
  ) {
    return window.sessionStorage.getItem(localStoragePrefix + label);
  } else {
    // TODO; fallback to cookies?
  }
}

export function clearStoredData() {
  if (
    'localStorage' in window
    && window.localStorage instanceof Storage
  ) {
    window.localStorage.clear();
  }

  if (
    'sessionStorage' in window
    && window.sessionStorage instanceof Storage
  ) {
    window.sessionStorage.clear();
  }
}

export function getUserId() {
  return get('user_id') || window.elpUserData.id || false;
}

export function uuid() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}
